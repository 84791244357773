import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import _ from 'lodash';
import { useFormik, FormikProvider } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';

import HomeLayout from '../components/HomeLayout';

import { HTMLContent } from '../components/Content'

import ResourcePageTemplate from '../components/ResourcePageTemplate'
import useMeta from '../lib/hoc/useMeta';
import '../graphql/newsFragment';

const createIndex = (data, fields, { meta, filterIds }) => {
  const airNodes = [];
  (data || []).map(({ node }) => {
    const { data, ...rest } = node;
    if(filterIds && !filterIds.includes(rest.recordId)) return null;

    airNodes.push({
      ...rest,
      ...data,
      catMeta: _.find(_.get(meta, 'frontmatter.categories', []), { name: rest.table }),
    });
    return null;
  });
  const rtn = {};
  const idIndexes = {};
  airNodes.forEach(node => {
    fields.forEach(field => {
      const keys = _.get(node, field);
      rtn[field] = rtn[field] ? rtn[field] : {};
      const fieldIndexes = rtn[field];
      _.castArray(keys || []).forEach(key => {
        if(key) {
          if(!_.has(fieldIndexes, [key])) {
            _.set(fieldIndexes, [key], []);
          }
          fieldIndexes[key].push(node.id);
        }
      });
    })
    idIndexes[node.id] = node;
  });
  // add id indexes
  rtn.id = idIndexes;
  return rtn;
};

const getNationalResourcesId = (data) => {
  return _.map(_.get(data, 'allAirtable.edges', []).filter(item => _.get(item, 'node.data.Area') === 'USA'), 'node.recordId');
};

const ResourcePage = ({ data, pageContext }) => {
  const filterForm = useFormik({
    initialValues: {
      zipcode: '',
      ids: null,
      tosFilter: {},
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({ zipcode: Yup.string().length(5)}),
    onSubmit: async (values) => {
      // call api to return resource
      let ids = null;
      if(values.zipcode) {
        const res = await axios.post(process.env.GATSBY_ZIPCODE_FILTER_ENDPOINT, { zipcode: values.zipcode });
        ids = [
          ..._.get(res, 'data.ids', []),
          ...getNationalResourcesId(data),
        ];
      }
      filterForm.setSubmitting(false);
      filterForm.setValues({ ...filterForm.values, ids });          
    },
  });
  // const { markdownRemark: post } = data
  // indexing airtable data for filtering purpose
  const indexes = createIndex(
    _.get(data, 'allAirtable.edges'),
    ['Type_of_Service'],
    {
      meta: _.get(data, 'markdownRemark'),
      filterIds: filterForm.values.ids,
    },
  );
  const defaultPost = {
    frontmatter: _.find(_.get(data, 'markdownRemark.frontmatter.categories', []), { name: _.get(pageContext, 'category') })
  }

  const metaData = useMeta();

  const post = _.defaultsDeep({
    frontmatter: _.find(_.get(metaData, 'meta.categories', []), { name: _.get(pageContext, 'category') })
  }, defaultPost);

  return (
    <HomeLayout headerText={_.get(post, 'frontmatter.description')}>
      <FormikProvider value={filterForm} >
        <ResourcePageTemplate
          contentComponent={HTMLContent}
          title={_.get(post, 'frontmatter.title', '')}
          content={_.get(post, 'html')}
          post={post}
          indexes={indexes}
          pageContext={pageContext}
        />
      </FormikProvider>
    </HomeLayout>
  )
}

ResourcePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const resourcePageQuery = graphql`
  query ResourcePage($locRegex: String, $category: String!) {
    markdownRemark(fields: { slug: { eq: "/meta/"} }) {
      html
      frontmatter {
        title
        categories {
          name
          title
          description
          actionText
          actionLink
          className
          thumbnailVisibility
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          slug
          airtable
        }
      }
    }
    allAirtable(filter: {
      data: {
        Area: { regex: $locRegex },
        in_Service_: { eq: "true" },
      }
      table: { eq: $category }
    }) {
      edges {
        node {
          ...resourceFragment
        }
      }
    }    
  }
`

export default ResourcePage;
