/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Grid, Pagination } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';
import { useQueryParam, NumberParam } from 'use-query-params';

import CatCard3 from './CatCard3';
import NotFound from '../NotFound';

const useEffectOnChange = (effect, value) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  if(!_.isUndefined(ref.current) && value !== ref.current) {
    ref.current = value;
    effect();
  }
}

export const CatList3 = props => {
  const rawQuery = typeof window === 'undefined' ? {} : null;
  const { cats, onClick } = props;
  const [page, $page] = useQueryParam('page', NumberParam, rawQuery);
  const [pageIndex, $pageIndex] = React.useState(page || 1);
  const [limitParam] = useQueryParam('limit', NumberParam, rawQuery);
  const [limit] = React.useState(limitParam || 5);
  const totalItems = _.get(cats, 'length', 0);
  // reset pagination whever cats changed
  useEffectOnChange(() => {
    $page(1);
    $pageIndex(1);
  }, totalItems);

  const totalPages = Math.ceil(totalItems / limit);
  return (
    <div className={cx(props.className)}>
      <div className="title text-h3 txt-color-3">
        {`View all resources: `}
        <span className="found">{`${totalItems} found`}</span>
      </div>
      {!!_.get(cats, 'length') ?
        <>
          <Grid >
          {
            _.castArray(_.get(_.chunk(cats, limit), pageIndex - 1) || []).map((cat, index) => {
              return (
                <Grid.Column key={cat.id} mobile={16} tablet={16} computer={16} className="col grid">
                  <CatCard3 cat={cat} onClick={() => onClick(cat)} />
                </Grid.Column>
              )
            })
          }
          </Grid>
          <div className="paging">
            <Pagination
              totalPages={totalPages}
              boundaryRange={0}
              ellipsisItem={null}
              siblingRange={1}
              activePage={pageIndex}
              
              firstItem={null}
              lastItem={null}
              pointing
              secondary

              onPageChange={(e, { activePage }) => {
                $page(activePage);
                $pageIndex(activePage);
              }}
            />
          </div>
        </>:
        <div style={{margin: '24px 0px'}}>
          <NotFound />
        </div>
      }
    </div>
  )
};

export default styled(CatList3)`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 15px !important;
  }
  > .title {
    font-weight: 600;
    margin-bottom: 0px;
    padding-left: 10px;
    padding-top: 10px;
    .found {
      font-weight: 400;
      color: #424242;
      margin-left: 10px;
    }
  }
  .ui.grid > .col.column.grid {
    @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .card {
      width: 100%;
      margin-bottom: 5px !important;
      margin-top: 0 !important;
    }
    &:first-child {
      .card {
        @media (max-width: ${({ theme }) => theme.mobile}) {
          margin-top: 15px !important;
        }
      }
    }
  }
  .paging {
    width: 100%;
    display: flex;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      justify-content: center;
    }
    justify-content: center;
    padding: 14px;
    .menu {
      .item.active, .item.active:hover {
        color: #005f64 !important;
        border-color: #005f64 !important;
      }
    }
  }
`;
