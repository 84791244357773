/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Grid, Button } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';

// import { getOneOf } from '../../lib/utils';
import ReactMarkdown from 'react-markdown';


const typeToColor = {
  'type-1': 'pink',
  'type-2': 'pink',
  'type-3': 'pink',
  'type-4': 'teal',
  'type-5': '',
}

const Div = ({ as, ...rest }) => {
  if(as) {
    const Component = as;
    return <Component {...rest} />;
  }
  return <div {...rest} />;
};

export const CatCard3 = props => {
  const { cat } = props;
  const { id, Name_of_Service } = cat;
  const locPath = `/resources/${_.kebabCase(Name_of_Service)}-${id}/`;
  const contacts = [
    {
      text: _.get(cat, 'Website') && 'WEBSITE',
      as: 'a',
      href: _.get(cat, 'Website', ''),
    },
    {
      text: _.get(cat, 'Email') && 'EMAIL',
      as: 'a',
      href: `mailto:${_.get(cat, 'Email', '')}`,
    },
    {
      text: _.get(cat, 'Phone_Number'),
    },
    {
      text: _.get(cat, 'Address'),
    },
    {
      text: _.compact([
        !!_.get(cat, 'City') && `${_.get(cat, 'City')},`,
        _.get(cat, 'State'),
        _.get(cat, 'Zip_Code'),
      ]).join(' '),
    },
  ]
  return (
    <div className={cx(props.className, _.get(cat, 'catMeta.className'))}>
      <Grid className="content">
        <Grid.Column className="header" mobile={16} tablet={16} computer={6}>
          <div className="tags">
            {_.castArray(_.get(cat, 'Type_of_Service', [])).map(tag => {
              return (<div className="tag text-small" key={tag}>{tag}</div>)
            })}
          </div>
          <div className="title text-h3">
            {_.get(cat, 'Name_of_Service', 'Name_of_Service')}
          </div>
          <div className="sub-title text-p">
            {_.get(cat, 'Organization_Name', 'Organization_Name')}
          </div>
          <div className="contacts">
            {
              _.map(contacts, item => {
                const { text, ...rest } = item;
                if(text) {
                  return (
                    <Div className="sub-title-2 text-p" key={text} {...rest}>
                      {text}
                    </Div>
                  );
                }
                return null;
              })
            }
          </div>
          <Button
            className="button-more"
            as="a"
            href={locPath}
            color={_.get(typeToColor, _.get(cat, 'catMeta.className'))}
          >LEARN MORE</Button>
        </Grid.Column>
        <Grid.Column className="details" mobile={16} tablet={16} computer={10}>
          {
            _.get(cat, 'Description_of_Service') && 
            <div className="desc-container">
              <div className="label text-p">Description</div>
              <div className="desc text-p">
                <ReactMarkdown source={_.get(cat, 'Description_of_Service', 'Description_of_Service')} />
              </div>
            </div>
          }
          {
            _.get(cat, 'Eligibility') && 
            <div className="desc-container eligibility">
              <div className="label text-p">Eligibility</div>
              <div className="desc text-p">
                <ReactMarkdown source={_.get(cat, 'Eligibility')} />
              </div>
            </div>
          }
          <Button
            className="button-more text-p"
            as="a" href={locPath}
            color={_.get(typeToColor, _.get(cat, 'catMeta.className'))}
          >LEARN MORE</Button>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default styled(CatCard3)`
  border: 1px solid #707070;
  border-radius: 20px;
  .content {
    padding: 20px 10px;
    font-weight: normal;
    .button-more {
      padding: 6px 20px;
      font-weight: 400;
      display: inline-block;
    }
    .header {
      margin-bottom: 20px;
      .button-more {
        @media (max-width: ${({ theme }) => theme.notDesktop}) {
          display: none;
        }  
      }
    }
    .details {
      .button-more {
        @media (min-width: ${({ theme }) => theme.notDesktop}) {
          display: none;
        }  
      }
    }
    .eligibility {
      @media (max-width: ${({ theme }) => theme.notDesktop}) {
        display: none;
      }  
    }
    .title {
      font-weight: 700;
    }
    .sub-title {
      font-weight: 500;
    }
    .contacts {
      @media (max-width: ${({ theme }) => theme.mobile}) {
        display: none;
      }
      margin-bottom: 20px;
      font-weight: normal;
      .sub-title-2 {
        color: ${props => props.theme.txtLight};
        display: inline-block;
        &:not(:first-child) {
          &:before {
            content: '|';
            margin: 0 2px;
            color: ${props => props.theme.txtLight};
          }
        }
      }  
    }
    .tags {
      .tag {
        display: inline-block;
        &:not(:first-child) {
          &:before {
            content: '|';
            margin: 0 2px;
            color: ${props => props.theme.txtLight};
          }
        }
      }
    }
    .desc-container {
      .label {
        font-weight: 700;
        margin-bottom: 0px;
      }
      .desc {
      }
      margin-bottom: 10px;
    }
  }
  height: 100%;
  &.type-1 {
    .content {
    }
    .title, .tos, .sub-title-2, .tag {
      color: ${props => props.theme.txtOrange};
    }
  }
  &.type-2 {
    .content {
    }
    .title, .tos, .sub-title-2, .tag {
      color: ${props => props.theme.txtTeal};
    }
  }
  &.type-3 {
    .content {
    }
    .title, .tos, .sub-title-2, .tag {
      color: ${props => props.theme.txtYellow};
    }
  }
  &.type-4 {
    .content {
    }
    .title, .tos, .sub-title-2, .tag {
      color: ${props => props.theme.txtPink};
    }
  }
`;
