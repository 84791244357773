import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Image } from 'semantic-ui-react';
import { useStaticQuery, graphql } from "gatsby";
import _ from 'lodash';
import { useMetaOverride } from '../lib/hoc/useMeta';

const metaQuery = graphql`
  query LoadingMetaQuery {
    markdownRemark(fields: { slug: { eq: "/meta/"}}) {
      frontmatter {
        loading {
          src {
            publicURL
          }
        }
      }
    }
  }
`;

const changeBg = props => keyframes`
  0% {
    background: ${props.theme.bgTeal};
  }
  33% {
    background: ${props.theme.txtPink};
  }
  66% {
    background: ${props.theme.txtOrange};
  }
  100% {
    background: #C3AE4F;
  }
`;

export const Loading = styled(({ className }) => {
  const data = useStaticQuery(metaQuery);
  const post = useMetaOverride('meta', data.markdownRemark);

  return (
    <div className={className}>
      <div className="content">
        <Image src={_.get(post, 'frontmatter.loading.src.0.publicURL')} width={44} height={64} />
      </div>
    </div>
  );
})`
  position: absolute;
  top: 30px;
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%) translateY(-50%);
  .content {
    color: white;
    width: 100px;
    height: 100px;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 56px;
      height: 56px;
      border-radius: 56px;
      img {
        width: 26px;
        height: 40px;
      }
    }
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: ${changeBg} 2s linear infinite;
  }
`;

export default Loading;
