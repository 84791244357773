/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';

import CatCard2 from './CatCard2';

export const CatList2 = props => {
  const { cats, onClick } = props;
  return (
    <div className={cx(props.className)}>
      {!!_.get(cats, 'length') && <div className="title text-h3 txt-color-3">View resources by type:</div>}
      <Grid >
        {
          cats.map((cat, index) => {
            const catType = _.get(['type-1', 'type-2', 'type-3', 'type-4', 'type-5'], index % 4);
            return (
              <Grid.Column key={cat.name} mobile={4} tablet={4} computer={4} className="col grid">
                <CatCard2 cat={cat} className={catType} onClick={() => onClick(cat)}/>
              </Grid.Column>
            )
          })
        }
      </Grid>

    </div>
  )
};

export default styled(CatList2)`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 15px !important;
  }
  > .title {
    font-weight: 600;
    margin-bottom: 0px;
    padding-left: 10px;
    padding-top: 10px;
  }
  .ui.grid > .col.column.grid {
    @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .card {
      width: 100%;
      margin-bottom: 5px !important;
      margin-top: 0 !important;
    }
    &:first-child {
      .card {
        @media (max-width: ${({ theme }) => theme.mobile}) {
          margin-top: 15px !important;
        }
      }
    }
  }

`;
