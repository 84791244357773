import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components';
import { Dimmer, Image, Segment, Container } from 'semantic-ui-react';
import _ from 'lodash';
import { useFormikContext } from 'formik';

import Loading from './Loading';
import Banner from './CatList/Banner';
import CatList2 from './CatList/CatList2';
import CatList3 from './CatList/CatList3';
import { getOneOf } from '../lib/utils';

const StyledContainer = styled(Container)`
  min-height: 40vh;
  margin-bottom: 40px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 20px;
  }
`;

export const ResourcePageTemplate = ({ post, indexes, pageContext }) => {
  // const [selectedTos, $selectedTos] = React.useState({});
  const [resIndexes] = React.useState(indexes.id);
  const filterForm = useFormikContext();

  const res = React.useMemo(() => {
    const tosFilters = [];
    _.map(filterForm.values.tosFilter, (value, tos) => {
      if(value) {
        tosFilters.push(tos);
      }
    });
    let ids = [];
    if(tosFilters.length) {
      tosFilters.forEach(tos => {
        ids = _.uniq([
          ...ids,
          ..._.castArray(_.get(indexes, ['Type_of_Service', tos]) || [])
        ]);
      });
    } else {
      ids = _.keys(getOneOf(indexes, 'id', {}));
    }
    return ids.map(id => resIndexes[id]);
  }, [JSON.stringify(filterForm.values)]);



  const onClickTos = (tos) => {
    const newVal = { ...filterForm.values.tosFilter, [tos.name]: !filterForm.values.tosFilter[tos.name] };
    filterForm.setValues({ ...filterForm.values, tosFilter: newVal });
  };

  return (
    <div>
      <Helmet>
        <title>BestFit</title>
        <link rel="canonical" href="https://best-fit.app/" />
      </Helmet>
      <section>
        <StyledContainer>
          <Banner
            item={_.get(post, 'frontmatter')}
            paths={[
              {
                label: 'Home',
                href: '/',
              },
              {
                label: _.get(pageContext, 'location'),
                slug: _.kebabCase(_.get(pageContext, 'location')),
              },
              {
                label: _.get(pageContext, 'category'),
              },
            ]} 

          />
          <div style={{position: 'relative'}}>
            <CatList2
              cats={_.keys(getOneOf(indexes, 'Type_of_Service', {})).map(tos => {
                return {
                  name: tos,
                  isSelected: !!filterForm.values.tosFilter[tos],
                };
              })}
              onClick={onClickTos}
            />
            {
              !filterForm.isSubmitting ?
              <CatList3 cats={res} /> :
              <>
                <LoadingDimmer />
                <LoadingWrapper /><Loading />
              </>
            }
          </div>
        </StyledContainer>
      </section>
    </div>
  )
}

const LoadingDimmer = styled(({ className }) => (
  <Dimmer.Dimmable className={className} as={Segment} blurring dimmed={true}>
    <Dimmer active={true} inverted onClickOutside={() => {}} />
    <p>
      <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
    </p>
    <p>
      <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
    </p>
    <p>
      <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
    </p>
    <p>
      <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
    </p>
  </Dimmer.Dimmable>
))`
  margin: 24px 0px !important;
  border-radius: 20px !important;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom:0;
  background: grey;
  opacity: 0.05;
`;

export default ResourcePageTemplate;
